import { Button, Label } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import ReactSelect from 'react-select';
import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';

const AdminWarehouseMetricsToolbar = (props) => {
  const {
    handleFilterChange = noop,
    onCopy = noop,
    onRefresh = noop,
    periods,
    properties,
    metrics,
    metricFilterControls = {},
    rows
  } = props;

  metricFilterControls.period_id.options = periods?.rows?.map(period => ({id: period?.id, name: period?.description}));
  metricFilterControls.property_id.options = properties?.map(property => ({id: property?.id, name: property?.name}));
  metricFilterControls.metric_id.options = metrics?.map(metric => ({id: metric?.id, name: metric?.name}));

  const metricCount = `${rows} Metrics`;

  return (
    <div className="m-1 p-1 d-flex justify-content-end bg-light border border-gray-20 rounded">
      <Label className="m-0 mt-1 ml-2 mr-2 " style={{fontSize: 14}}>Filter</Label>
      <FormInputSelect selectFirst={false} className="mr-2" size="30px" handleChange={(event) => handleFilterChange(event, 'metric_id')} control={metricFilterControls.metric_id} />
      <FormInputSelect selectFirst={false} className="mr-2" size="30px" handleChange={(event) => handleFilterChange(event, 'period_id')} control={metricFilterControls.period_id} />
      <FormInputSelect selectFirst={false} className="mr-2" size="30px" handleChange={(event) => handleFilterChange(event, 'property_id')} control={metricFilterControls.property_id} />
      {/* <FormInputSelect selectFirst={false} className="mr-2" size="30px" handleChange={(event) => handleFilterChange(event, 'metric_id')} control={metricFilterControls.group_id} /> */}

      <Label className="m-0 mt-1 ml-2 mr-2" style={{fontSize: 14}}>Showing {metricCount}</Label>

      <div>
        <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
          <Icon name="rotate" />
        </Button>
        <Button onClick={onCopy} size="sm" color="warning" className="ml-1">
          Copy to Clipboard
        </Button>
      </div>

    </div>
  );
};

export default AdminWarehouseMetricsToolbar;
