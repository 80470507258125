import { get, put, remove } from 'jsx/lib/genericAction';

import {
  apiWarehouse,
} from 'jsx/constants/api';

const addFiltersToParams = (params, filters) => {
  Object.keys(filters).forEach((key) => (params[`filter_${key}`] = filters[key]));
  return params;
};

export function fetchWarehouseMetrics(params, filters) {
  if (filters) params = addFiltersToParams(params, filters);

  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_METRICS`, `${apiWarehouse}/metrics`, params);
}

export function fetchWarehouseUserProbes(params) {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_USER_PROBES`, `${apiWarehouse}/probes/currentuser`, params);
}

export function removeProbe(property_id, period_id) {
  return (dispatch) => remove(dispatch, 'REMOVE_WAREHOUSE_PROBE', `${apiWarehouse}/probes/${property_id}/${period_id}`);
}

export function fetchWarehouseProbes(params) {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_PROBES`, `${apiWarehouse}/probes`, params);
}

export function fetchGroupsForProbe(row_id, period_id) {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_GROUPS_FOR_PROBE`, `${apiWarehouse}/groups/assocs/probe/${row_id}/${period_id}`);
}

export function fetchWarehouseProperty(period_id, property_id) {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_PROPERTY`, `${apiWarehouse}/properties/${period_id}/${property_id}`);
}

export function fetchWarehouseEligibleProperties(period_id) {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_ELIGIBLE_PROPERTIES`, `${apiWarehouse}/properties/eligible/${period_id}`);
}

export function fetchWarehouseDistinctProperties() {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_DISTINCT_PROPERTIES`, `${apiWarehouse}/properties/distinct`);
}

export function fetchWarehouseGroups(params) {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_GROUPS`, `${apiWarehouse}/groups`, params);
}

export function updateWarehouseGroup(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_WAREHOUSE_GROUP', `${apiWarehouse}/groups/${data.id}`, data);
}

export function fetchWarehouseGroup(period_id, group_id) {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_GROUP`, `${apiWarehouse}/groups/${period_id}/${group_id}`);
}

export function fetchWarehouseGroupAssocs(parent_id, column_tag) {
  return async (dispatch) => await get(dispatch, `FETCH_WAREHOUSE_GROUP_ASSOCS`, `${apiWarehouse}/groups/assocs/${parent_id}/${column_tag}`);
}


