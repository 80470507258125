/* eslint-disable no-alert */
import { connect } from 'react-redux';
import { Card, Nav, TabContent, TabPane, Button } from 'reactstrap';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import FormInput from '../../../core/form/components/FormInput';
import PageTitle from '../../../core/form/components/PageTitle';
import {
  updateControls,
  saveControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import Mapster from './Mapster.jsx';
import { controls as controlsTemplate } from '../forms/client';
import { controls as userControlsTemplate } from '../forms/client_users';
import { dncControls as propertyControlsTemplate } from '../../portrait/forms/properties';
import Subscriptions from '../components/clients/Subscriptions.jsx';

import GenericLsv from '../../../core/form/components/GenericLsv';
import ClientUserToolbar from '../components/clients/ClientUserToolbar.jsx';

import { fetchOrg, updateOrg, fetchUsers } from '../../../manage/actions';
import { fetchProperties } from '../actions/properties';
import { masqueradeRequest } from '../../../core/authentication/actions';

class Client extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      expandMap: false,
      showMiniMap: false,
      controls: cloneDeep(controlsTemplate),
      user_controls: cloneDeep(userControlsTemplate),
      propertyControls: cloneDeep(propertyControlsTemplate),
      backlink: '/home/clients',
      mapSources: [],
      data: {},
      project: {},
      isInviteOpen: false,
    };

    this.masquerade = this.masquerade.bind(this);
    this.onClientClick = this.onClientClick.bind(this);
    this.onEditProperty = this.onEditProperty.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.setInviteModal = this.setInviteModal.bind(this);
    this.toggleEnterprisesTab = this.toggleEnterprisesTab.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.toggleMiniMap = this.toggleMiniMap.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
  }

  async componentDidMount() {
    let { data, controls } = this.state;

    const { id } = this.props.match.params;

    data = await this.props.dispatch(fetchOrg(id));
    controls = updateControls(controls, data);

    // Setting current client in reducer in preparation for passing client org id to api
    this.props.dispatch({ type: 'SET_CURRENT_CLIENT', payload: data });

    this.props.dispatch(fetchUsers({ org_id: id }));

    if (this.props.clients.activeTab === '4')
      await this.props.dispatch(fetchProperties({ filter_org_id: data.id }));

    this.setState({
      data,
      controls,
    });
  }

  async masquerade(id) {
    const confirmed = window.confirm(
      'Masquerading selected user and logging into their instance of the portal as if you were them. Only Administrators are able to do this. Continue?',
    );

    if (confirmed) {
      const url = await this.props.dispatch(masqueradeRequest(id, 'client'));
      window.open(url, '_blank');
    }
  }

  async onSave() {
    const { controls } = this.state;
    let { data } = this.state;

    data = saveControls(controls, data);

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (isValid) {
      const success = await this.props.dispatch(updateOrg(data));

      if (success) this.props.history.goBack();
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      `Removing the ${data.name.toUpperCase()} client permanently with all its associated data, properties and tables. Continue?`,
    );
    if (confirmed) {
      this.props.history.push(this.state.backlink);
    }
  }

  async setInviteModal(isInviteOpen) {
    this.setState({ isInviteOpen });
  }

  toggleTab(tab, tag) {
    const { data } = this.state;
    const { id } = this.props.match.params;

    switch (tag) {
      case 'users':
        this.props.dispatch(fetchUsers({ org_id: id }));
        break;
      case 'properties':
        this.props.dispatch(fetchProperties({ filter_org_id: data.id }));
        break;
      default:
        break;
    }

    if (this.props.clients.activeTab !== tab) {
      this.props.dispatch({ type: 'SET_ACTIVE_CLIENT_TAB', payload: tab });
    }
  }

  toggleEnterprisesTab(tab) {
    this.props.dispatch({ type: 'SET_ENTERPRISES_ACTIVE_TAB', payload: tab });
  }

  toggleMap() {
    this.setState({
      expandMap: !this.state.expandMap,
    });
  }

  toggleMiniMap() {
    this.setState({
      showMiniMap: !this.state.showMiniMap,
    });
  }

  onEditProperty(id) {
    this.props.history.push(`/home/portrait/property/${id}`);
  }

  onClientClick(id) {
    if (!this.checkAccess('clientUserEdit')) return;

    this.props.history.push(`/home/users/${id}`, {
      isChildAppResetPassword: true,
    });
  }

  render() {
    const { activeTab } = this.props.clients;

    const { mapSources, expandMap, user_controls, controls, data, isInviteOpen, propertyControls } =
      this.state;

    const { users } = this.props.manage;

    const { properties } = this.props.properties;

    const lngLat = [150.7333, -23.1333];

    const iconName = 'restroom';
    const title = data.name;
    const description = '';
    const org_id = this.props.match.params.id;

    const actions = [{ func: this.masquerade, iconName: 'mask', tooltip: 'Masquerade' }];

    return (
      <div>
        {!this.state.expandMap && (
          <div className="p-3">
            <div className="d-flex justify-content-between p-2">
              <div className="d-flex justify-content-start">
                <Button size="sm" color="link" onClick={this.props.history.goBack} className="mr-2">
                  <Icon size="1x" name="chevron-left" className="mr-2" />
                  Back
                </Button>
                <PageTitle title={title} description={description} iconName={iconName} />
              </div>

              <div>
                <Icon name="list" style={{ color: '#bbbbbb' }} className="mr-3" />
                <Icon
                  name="location-pin"
                  className="text-primary mr-3"
                  style={{ cursor: 'pointer' }}
                  onClick={this.toggleMiniMap}
                />
              </div>
            </div>
            {this.state.showMiniMap && (
              <Card className="border rounded mt-2" style={{ minHeight: '300px' }}>
                <Mapster
                  handleSourceVisibility={this.handleSourceVisibility}
                  expandMap={expandMap}
                  lngLatCenter={lngLat}
                  toggleMap={this.toggleMap}
                  mapSources={mapSources}
                />
              </Card>
            )}

            <Nav tabs className="mt-2">
              <FormTab
                caption="Profile"
                tabId="1"
                activeTab={activeTab}
                toggle={this.toggleTab}
                tabTag="profile"
              />
              <FormTab
                caption="Users"
                tabId="2"
                activeTab={activeTab}
                toggle={this.toggleTab}
                tabTag="users"
              />
              <FormTab
                caption="Subscriptions"
                tabId="subscriptions"
                activeTab={activeTab}
                toggle={this.toggleTab}
                tabTag="subscriptions"
                disabled={!this.checkAccess('clientSubscriptionsTab')}
              />
              <FormTab
                caption="Properties"
                tabId="4"
                activeTab={activeTab}
                toggle={this.toggleTab}
                tabTag="properties"
              />
              <FormTab
                caption="Enterprises"
                tabId="5"
                activeTab={activeTab}
                toggle={this.toggleTab}
                disabled
                tabTag="enterprises"
              />
              <FormTab
                caption="Documents"
                tabId="6"
                activeTab={activeTab}
                toggle={this.toggleTab}
                disabled
              />
              <FormTab
                caption="Comments"
                tabId="7"
                activeTab={activeTab}
                toggle={this.toggleTab}
                disabled
              />
              <FormTab
                caption="Activity"
                tabId="8"
                activeTab={activeTab}
                toggle={this.toggleTab}
                disabled
              />
              <FormTab
                caption="Advanced"
                tabId="9"
                activeTab={activeTab}
                toggle={this.toggleTab}
                disabled
              />
            </Nav>

            <TabContent activeTab={activeTab} className="border-bottom border-light">
              <TabPane tabId="1" className="mb-2 p-3">
                <FormInput handleChange={this.handleChange} control={controls.name} />
                <div className="d-flex justify-content-center m-2">
                  <Button disabled={!this.checkAccess('clientUpdate')} className="mr-2" size="sm" color="success" onClick={() => this.onSave()}>
                    Save Client
                  </Button>
                  <Button className="mr-2" size="sm" color="light" onClick={this.onCancel}>
                    Cancel
                  </Button>
                  <Button disabled size="sm" color="danger" onClick={this.onCancel}>
                    Delete
                  </Button>
                </div>
              </TabPane>
              <TabPane tabId="2" className="mb-2 p-3">
                <ClientUserToolbar
                  setModal={this.setInviteModal}
                  isOpen={isInviteOpen}
                  orgId={org_id}
                  isChildAppInvite
                  showSendButton={false}
                  disabled={!this.checkAccess('clientUserEdit')}
                />
                <GenericLsv
                  controls={user_controls}
                  iconName="user"
                  emptyCaption="No Users found"
                  onClick={this.onClientClick}
                  rows={users}
                  actions={actions}
                />
              </TabPane>
              <TabPane tabId="subscriptions" className="mb-2 p-3">
                <Subscriptions orgId={org_id} />
              </TabPane>
              <TabPane tabId="4" className="mb-2 p-3">
                <GenericLsv
                  controls={propertyControls}
                  iconName="draw-polygon"
                  emptyCaption="No Properties found"
                  onClick={this.onEditProperty}
                  rows={properties}
                />
              </TabPane>
            </TabContent>
          </div>
        )}
        {this.state.expandMap && (
          <Mapster
            expandMap={expandMap}
            lngLatCenter={lngLat}
            toggleMap={this.toggleMap}
            mapSources={mapSources}
          />
        )}
      </div>
    );
  }
}

const mapStoreToProps = (store) => ({
  clients: store.clients,
  properties: store.properties,
  mapster: store.mapster,
  realm: store.realm,
  crm: store.crm,
  manage: store.manage,
});

export default connect(mapStoreToProps)(withContainerError(Client));
