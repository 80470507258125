import moment from 'moment';
import { noop } from 'lodash';
import Listview from '../../../../core/form/components/Listview';

const AdminWarehouseMetricsLsv = ({ rows, onClick = noop, darkTable }) => {
  const renderRows = (headers, record) => {
    const extraClass = '';

    const tableTd = headers.map(({ caption, field, classes, sortColumn, styles }, index) => {
      switch (field) {
        case 'updated': {
          return (
            <td key={index} className={`${classes} ${extraClass}`} data-sort-value={record[sortColumn]}>
              {moment(record.updated).format('YYYY-MM-DD HH:mm:ss') }
            </td>
          );
        }
        case 'metric':
          return (
            <td key={index} className={`${classes} ${extraClass}`} style={styles}>{record?.metric?.key}</td>
          );
        case 'property':
          return (
            <td key={index} className={`${classes} ${extraClass}`} style={styles}>{record?.property?.name}</td>
          );
        case 'kpigroup':
          return (
            <td key={index} className={`${classes} ${extraClass}`} style={styles}>{record?.kpigroup?.caption}</td>
          );
        case 'period':
          return (
            <td key={index} className={`${classes} ${extraClass}`} style={styles}>{record?.period?.description}</td>
          );
        case 'client_value':
          return (
            <td key={index} className={`${classes} ${extraClass}`} style={styles}>{record[field]?.toLocaleString()}</td>
          );
        case 'benchmark_value':
          return (
            <td key={index} className={`${classes} ${extraClass}`} style={styles}>{record[field]?.toLocaleString()}</td>
          );
        default:
          return (
            <td key={index} className={`${classes} ${extraClass}`} style={styles}>{record[field]}</td>
          );
      }

    });
    return tableTd;
  };

  const iconName = 'gauge-circle-bolt';
  const emptyCaption = 'No Metrics found';

  const headers = [
    { caption: 'Metric', field: 'metric', classes: 'text-left', styles: {width: '200px'}},
    { caption: 'Period', field: 'period', classes: 'text-left', styles: {width: '130px'}},
    { caption: 'Property', field: 'property', classes: 'text-left' },
    { caption: 'KPI Group', field: 'kpigroup', classes: 'text-left' },
    { caption: 'Client Entered', field: 'client_value', classes: 'text-right', styles: {width: '130px'}},
    { caption: 'Benchmark Value', field: 'benchmark_value', classes: 'text-right', styles: {width: '130px'}},
    { caption: 'Updated', field: 'updated', classes: 'text-left', styles: {width: '150px'}}
  ];

  const tableHeadTh = headers.map(({ caption, classes, styles }, index) => (
    <th key={index} className={classes} style={styles}>
      {caption}
    </th>
  ));

  let tableBodyTr = [];
  let finalRows = rows;
  if (rows?.rows) finalRows = rows.rows;

  const haveRows = finalRows && finalRows.length > 0;
  if (haveRows) {
    tableBodyTr = finalRows.map((row) => (
      <tr key={row.id} >
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={finalRows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
      darkTable={darkTable}
    />
  );
};

export default AdminWarehouseMetricsLsv;
